@import '../../common/styles/variables';

.form__container {
    color: $light-navy;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 18px;

    &__title {
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.4px;
        color: $light-navy;
        padding-bottom: 8px;
        line-height: 1.2em;
    }

    &__subtitle {
        width: 100%;
        font-size: 16px;
        letter-spacing: 0.4px;
        color: $light-navy;
        padding-bottom: 16px;
    }

    &__input {
        width: 100%;
    }

    &__submit {
        margin: 24px auto 0;
        cursor: pointer;
    }

    &.hidden {
        display: none;
    }
}

.form__success {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: $light-navy;
    visibility: visible;
    position: relative;
    transform: scaleY(1);
    transform-origin: top;
    transition: all 0.15s ease-in;
    transition-delay: 0.1s;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.hidden {
        position: absolute;
        transform: scaleY(0);
        visibility: hidden;
        top: -60px;
    }

    img {
        width: 24px;
        height: 24px;
        padding: 16px;
    }
}

.form__failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light-navy;
    font-size: 20px;
    letter-spacing: 0.4px;
    visibility: visible;
    position: relative;
    transform: scaleY(1);
    transform-origin: top;
    transition: all 0.15s ease-in;
    transition-delay: 0.1s;

    &__block {
        display: flex;
        flex-direction: row;
        align-items: center;
        // padding-bottom: 16px;

        img {
            width: 24px;
            height: 24px;
            padding: 16px;
        }
    }

    &.hidden {
        position: absolute;
        transform: scaleY(0);
        visibility: hidden;
        top: -60px;
    }
}
