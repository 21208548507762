.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0);
}

.carousel_slide__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    color: white;
    position: relative;

    @media screen and (min-width: 992px) {
        min-height: calc(100vh - 64px);
    }

    &__text {
        text-align: center;
        line-height: initial;
        padding: 0 20px;
        z-index: 2;
        font-size: 2em;
        margin-bottom: 0;
        margin-top: 3.5em;

        @media screen and (min-width: 992px) {
            position: absolute;
            top: 25%;
        }
    }

    &__button_container {
        z-index: 2;
        font-size: 1.7em;
        margin-bottom: 0;
        margin-top: 1.5em;

        @media screen and (min-width: 992px) {
            margin-top: 6.5em;
            position: absolute;
            top: 25%;
        }
    }

    &__img-holder {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        a,
        img {
            height: 100%;
            min-width: 100%;
            object-fit: cover;
            object-position: center;
        }

        .overlay {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(19, 64, 130, 0.45);
        }
    }
}
