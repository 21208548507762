.navigation-menu__top {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 12px 16px 12px 36px;
        font-size: 16px;
        cursor: pointer;

        &.child {
            color: #8ec6e7;
        }

        &:not(:last-child) {
            border-bottom: 1px solid white;
        }

        img {
            height: 11px;
        }

        &:hover {
            background: #0096db99;
            color: white;
        }

        &.selected {
            background: #0096db;
            color: white;
        }

        &.back {
            justify-content: flex-end;
            flex-direction: row-reverse;
            text-transform: uppercase;
            color: white;

            span {
                padding-left: 36px;
            }
        }
    }
}
