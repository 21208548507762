@import '../../common/styles/variables';

.button__element {
    font-size: 18px;
    border: none;
    background-color: $light-navy;
    color: $white;
    min-width: 180px;
    height: 46px;
    border-radius: 4px;
    transition: transform 0.1s;
    font-family: 'Lora';

    &:hover {
        transform: scale(1.02);
    }

    &:hover:disabled {
        transform: none;
    }

    &:disabled {
        background-color: $light-blue;
    }
}
