@import '../../common/styles/variables';

.home__always-blue-bg {
    background: $new-light-blue;
}

.home__form_container {
    // Tablet or more
    @media screen and (min-width: $break-tablet) {
        background: $new-light-blue;
    }
}

.home__contact-details_container {
    background: $new-light-blue;
    // Tablet or more
    @media screen and (min-width: $break-tablet) {
        background: none;
    }
}

.home__banner-bg {
    @media screen and (min-width: $break-m) {
        background-position: 0px -106px !important;
    }
}
