@import '../../../common/styles/variables';

.first-free__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light-navy;
    padding: 32px;

    &__title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.8px;
        text-align: center;
        padding-bottom: 12px;
    }

    &__subtitle {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.3px;
        text-align: center;
    }
}
