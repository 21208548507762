.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.imageContainer {
    width: 100%;
    height: 100vh;
    background: #f4d937;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.constructionImage {
    max-width: 100%;
    max-height: 480px;
    height: auto;
}

.textContainer {
    padding: 30px 0;
}

.ui.fixed.menu + .ui.grid {
    padding-top: 1rem;
}

.main-content__container {
    min-height: calc(100vh - 190px);
    width: 100%;
}
