@import "../../common/styles/variables";

.input__container {
    margin: 8px 0;

    &__label {
        font-size: 16px;
        letter-spacing: 0.4px;
    }

    &__field, &__textarea {
        margin-top: 4px;
        width: 100%;
        padding: 8px;
        font-size: 16px;
        font-family : inherit;
        box-sizing: border-box;
        background-color: $light-blue;
        border: none;

        // &:invalid {
        //     border: 1px solid #e8730e;
        // }
    }

    &__textarea {
        display: block;
        resize: none;
    }
}