@import '../../../common/styles/variables';

.call-us__container {
    color: $light-navy;
    padding: 18px 32px !important;

    @media screen and (min-width: $break-m) {
        padding: 0 30px;
    }

    @media screen and (min-width: $break-l) {
        padding: 0 60px;
    }

    &__title {
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.44px;
        color: $light-navy;
        padding-bottom: 16px;
        line-height: 1.2em;
    }
    &__subtitle {
        width: 100%;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: $light-navy;
        padding-bottom: 16px;
    }

    &__details {
        &__block {
            display: flex;
            align-items: top;
            padding: 8px 0;
            font-size: 20px;

            &__icon {
                width: 32px;
                height: 32px;
                padding-right: 12px;
            }

            &__info {
                line-height: 24px;
            }
        }
    }

    a {
        color: inherit;
        width: 100%;
    }
}
