@import '../../../common/styles/variables';

.services__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light-navy;
    padding: 32px;
    width: 100%;

    &__anchor {
        display: block;
        position: relative;
        top: -80px;
        visibility: hidden;
    }

    &__title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.8px;
        text-align: center;
        padding-bottom: 40px;
    }

    &__subtitle {
        font-size: 14px;
        padding-top: 8px;
        letter-spacing: 0.3px;
        text-align: center;
        color: #134082;
    }

    img {
        border-radius: 50%;
    }

    .column {
        text-align: center;
    }
}
