@import './variables';

body {
    margin: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
    font-family: 'Lora', serif;
}

.section__container {
    padding: 32px 30px;

    // Overrides
    &.sbg__light-dark {
        background: $light-dark;
    }

    &.sbg__light-navy {
        background: $light-navy;
    }

    @media screen and (min-width: $break-m) {
        padding: 32px 100px;
    }
}

.hide {
    @media screen and (max-width: $break-s) {
        &__s {
            display: none;
        }
    }

    @media screen and (min-width: $break-s + 1) and (max-width: $break-m) {
        &__m {
            display: none;
        }
    }

    @media screen and (min-width: $break-m + 1) and (max-width: $break-l) {
        &__l {
            display: none;
        }
    }

    @media screen and (min-width: $break-l + 1) {
        &__xl {
            display: none;
        }
    }
}

.underline {
    text-decoration: underline;
}

.no-padding {
    padding: 0px;
}

.no-padding-top {
    padding-bottom: 0px;
}

.no-padding-top {
    padding-top: 0px;
}

.no-padding-sides {
    padding-right: 0px;
    padding-left: 0px;
}

.no-padding-right {
    padding-right: 0px;
}

.flex {
    display: flex;
}

.flex-center {
    align-items: flex-start;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-space-around {
    justify-content: space-around;
}

.flex-1 {
    flex: 1;
}
