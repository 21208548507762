// Variables

// Color palette
$light-navy: #134082;
$navy: #5689c3;
$blue: rgb(142, 198, 231);
$light-blue: rgba(142, 198, 231, 0.3);
$white: white;

// New Palette
$new-navy: #0e254a;
$new-light-blue: rgba(142, 198, 231, 0.1);

// Backgrounds
$light-dark: rgba(142, 198, 231, 0.1);

// Responsive breakpoints
$break-xs: 480px;
$break-s: 860px;
$break-m: 860px;
$break-l: 1280px;
$break-xl: 1690px;

// New Breakpoints
$break-tablet: 768px;
