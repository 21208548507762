.navigation__container {
    height: 64px;

    @media screen and (min-width: 992px) {
        height: 84px;
    }

    .logo {
        width: 100px;

        @media screen and (min-width: 992px) {
            width: 130px;
        }
    }
}
