@import '../../../../common/styles/variables';

.content-base__container {
    padding-top: 0 !important;

    .image-container {
        width: 100%;
        background-size: cover;
    }
    &__title {
        position: absolute;
        bottom: 72px;
        left: 40px;
        font-size: 24px;
        color: white;
        font-style: italic;
        letter-spacing: 1px;
    }

    &__subtitle {
        position: absolute;
        bottom: 24px;
        right: 24px;
        font-size: 14px;
        color: white;
        font-weight: bold;
    }

    &__section {
        padding: 30px 24px 24px;
        color: #5e636a;
        letter-spacing: 0.58px;
        width: 100%;

        h1 {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 1px;
            color: $light-navy;
        }

        h3 {
            font-size: 14px;
            color: #0096db;
            font-weight: normal;
        }

        h4 {
            font-size: 16px;
            font-weight: bold;
            color: $light-navy;
        }

        p {
            font-size: 14px;

            a {
                text-decoration: underline;
                color: #0096db;
            }
        }

        &.contact {
            img {
                width: 20px;
                height: 20px;
                margin-right: 16px;
            }

            a {
                font-size: 20px;
                letter-spacing: 0.44px;
                color: #184080;
            }
        }
    }
}

.content-base__container__large {
    &__title {
        position: absolute;
        bottom: 72px;
        left: 40px;
        font-size: 24px;
        color: white;
        font-style: italic;
        letter-spacing: 1px;

        @media screen and (max-width: 992px) {
            font-size: 20px;
        }
    }

    &__subtitle {
        position: absolute;
        bottom: 24px;
        right: 24px;
        font-size: 14px;
        color: white;
        font-weight: bold;
    }

    h1 {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        color: $light-navy;
    }

    h3 {
        font-size: 14px;
        color: #0096db;
        font-weight: normal;
    }

    h4 {
        font-size: 16px;
        font-weight: bold;
        color: $light-navy;
    }

    p {
        font-size: 14px;

        a {
            text-decoration: underline;
            color: #0096db;
        }
    }

    &__section {
        padding: 30px 24px 24px;
        color: #5e636a;
        letter-spacing: 0.58px;
        width: 100%;

        &.contact {
            img {
                width: 20px;
                height: 20px;
                margin-right: 16px;
            }

            a {
                font-size: 20px;
                letter-spacing: 0.44px;
                color: #184080;
            }
        }
    }
}
