@import '../../common//styles/variables';

.footer__container {
    color: $white;
    font-size: 14px;
    letter-spacing: 0.3px;
    padding: 32px;
    background: #0e254a;
    width: 100%;

    @media screen and (min-width: $break-m) {
        text-align: center;
    }

    .item {
        margin: 8px 0;
    }

    &__link {
        width: 100%;
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        overflow: visible;
        text-transform: none;
        -webkit-appearance: button;
        color: white;
        background: none;
        border: none;
        text-align: left;
        padding: 0;
        letter-spacing: inherit;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            outline: none;
        }
    }

    &__text {
        font-style: italic;
    }

    &__copyright {
        letter-spacing: 0.4px;
        font-size: 16px;
        font-family: 'Butler';
    }
}
