@import '../../../common/styles/variables';

.contact-details__container {
    color: $light-navy;
    padding: 18px;

    @media screen and (min-width: $break-m) {
        padding: 0 30px;
    }

    @media screen and (min-width: $break-l) {
        padding: 0 60px;
    }

    &__title {
        font-size: 24px;
        padding-bottom: 24px;
        line-height: 1.2em;
    }

    &__info-block {
        display: flex;
        align-items: top;
        padding: 16px 0;
        font-size: 14px;

        &__icon {
            width: 32px;
            height: 32px;
            padding-right: 12px;
        }

        &__info {
            line-height: 24px;

            &__sub {
                color: $navy;
            }

            a:visited {
                color: $light-navy;
            }
        }
    }
}
