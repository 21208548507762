@import '../../../common/styles/variables';

.curriculum__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light-navy;
    padding: 32px;

    &__title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.8px;
        text-align: center;
        padding-bottom: 12px;
    }
    &__mid {
        display: flex;
        // padding: 40px 0 50px 0;
        padding: 40px 0 0px 0;
        align-items: center;

        &__subtitle {
            font-size: 14px;
            letter-spacing: 0.3px;
            padding-right: 8px;
        }

        img {
            width: 50%;
        }
    }
}
