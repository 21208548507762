.list-base__container {
    padding-top: 0 !important;

    .image-container {
        width: 100%;
        height: 260px;
    }

    &__title {
        position: absolute;
        top: 180px;
        left: 40px;
        font-size: 24px;
        color: white;
        font-weight: bold;
    }

    ul {
        width: 100%;
        padding-left: 75px;

        > li {
            a {
                color: #0096db;
            }
            font-size: 14px;
            padding: 12px 0;
        }
    }
}
